const configSapians = {
  selfURL: import.meta.url,
  content: [
    "./components/**/*.tsx",
    "./islands/**/*.tsx",
    "./views/**/*.tsx",
    "./components/definition.ts",
  ],
  theme: {
    colors: {
      base1: {
        content: "#000000",
        DEFAULT: "#FFFFFF",
        default: "#FFFFFF",
        alt: "#FFFFFF",
      },
      base2: {
        content: "#000000",
        DEFAULT: "#E8E8E1",
        default: "#E8E8E1",
        alt: "#E8E8E1",
      },
      base3: {
        content: "#000000",
        DEFAULT: "#D6D6CB",
        default: "#D6D6CB",
        alt: "#D6D6CB",
      },
      base4: {
        content: "#000000",
        DEFAULT: "#C5C5B4",
        default: "#C5C5B4",
        alt: "#C5C5B4",
      },
      primary: {
        content: "#FFFF69",
        DEFAULT: "#3A4642",
        default: "#3A4642",
        alt: "#313A37",
      },
      secondary: {
        content: "#3A4642",
        DEFAULT: "#E8E8E2",
        default: "#E8E8E2",
        alt: "#DDDDD9",
      },
      accent: {
        content: "#000000",
        DEFAULT: "#FFFF69",
        default: "#FFFF69",
        alt: "#FFFF69",
      },
      neutral: {
        content: "#3A4642",
        DEFAULT: "#C5C5B4",
        default: "#C5C5B4",
        alt: "#C0C0B1",
      },
      info: {
        content: "#FFFF69",
        DEFAULT: "#005082",
        default: "#005082",
        alt: "#0B4A71",
      },
      success: {
        content: "#FFFF69",
        DEFAULT: "#46CDA0",
        default: "#46CDA0",
        alt: "#45BB94",
      },
      warning: {
        content: "#FFFF69",
        DEFAULT: "#A55FD7",
        default: "#A55FD7",
        alt: "#9550C7",
      },
      danger: {
        content: "#FFFF69",
        DEFAULT: "#FF6E1E",
        default: "#FF6E1E",
        alt: "#E55F14",
      },
      //TODO: set correct alts
      chart1: {
        content: "#ffffff",
        DEFAULT: "#343c38",
        default: "#343c38",
        alt: "#8C97FD",
      },
      chart2: {
        content: "#ffffff",
        DEFAULT: "#73caa1",
        default: "#73caa1",
        alt: "#b6ebda",
      },
      chart3: {
        content: "#ffffff",
        DEFAULT: "#214f81",
        default: "#214f81",
        alt: "#abc6d6",
      },
      chart4: {
        content: "#ffffff",
        DEFAULT: "#2b26a3",
        default: "#2b26a3",
        alt: "#abc6d6",
      },
      chart5: {
        content: "#ffffff",
        DEFAULT: "#955ad4",
        default: "#955ad4",
        alt: "#bfbbe2",
      },
      chart6: {
        content: "#ffffff",
        DEFAULT: "#FF6E1E",
        default: "#FF6E1E",
        alt: "#FF9961",
      },
      chart7: {
        content: "#ffffff",
        DEFAULT: "#9f9f90",
        default: "#9f9f90",
        alt: "#9BED74",
      },
      chart8: {
        content: "#ffffff",
        DEFAULT: "#038301",
        default: "#038301",
        alt: "#69AF68",
      },
      chart9: {
        content: "#ffffff",
        DEFAULT: "#004B50",
        default: "#004B50",
        alt: "#649DA0",
      },
      chartothers: {
        content: "#ffffff",
        DEFAULT: "#000000",
        default: "#000000",
        alt: "#CDCDCD",
      },
      disabled: {
        content: "#ffffff",
        DEFAULT: "#BBBBBB",
        default: "#BBBBBB",
        alt: "#AAAAAA",
      },
    },
    // deno-lint-ignore no-explicit-any
    fill: (theme: any) => ({
      ...theme("colors"),
    }),
    spacing: {
      none: "0",
      // 8px
      xs: "0.5rem",
      // 12px
      s: "0.75rem",
      // 16px
      m: "1rem",
      // 24px
      l: "1.5rem",
      // 32px
      xl: "2rem",
      // 48px
      "2xl": "3rem",
      // 64px
      "3xl": "4rem",
      // 80px
      "4xl": "5rem",
      full: "100%",
      DEFAULT: "1rem",
    },
    height: {
      none: "0",
      // 8px
      xs: "0.5rem",
      // 12px
      s: "0.75rem",
      // 16px
      m: "1rem",
      // 24px
      l: "1.5rem",
      // 32px
      xl: "2rem",
      // 48px
      "2xl": "3rem",
      // 64px
      "3xl": "4rem",
      // 80px
      "4xl": "5rem",
      full: "100%",
      screen: "100vh",
      DEFAULT: "1rem",
    },

    width: {
      none: "0",
      // 8px
      xs: "0.5rem",
      // 12px
      s: "0.75rem",
      // 16px
      m: "1rem",
      // 24px
      l: "1.5rem",
      // 32px
      xl: "2rem",
      // 48px
      "2xl": "3rem",
      // 64px
      "3xl": "4rem",
      // 80px
      "4xl": "5rem",
      full: "100%",
      DEFAULT: "1rem",
    },
    fontSize: {
      xs: "0.625rem",
      s: "0.75rem",
      m: "1rem",
      l: "1.25rem",
      xl: "1.5rem",
      "2xl": "2rem",
      "3xl": "2.625rem",
      "4xl": "3.5rem",
      DEFAULT: "1rem",
    },
    fontFamily: {
      "primary-light": '"Outfit Light", system-ui, Arial, sans-serif',
      "primary-light-italic":
        '"Outfit Light Italic", system-ui, Arial, sans-serif',
      "primary": '"Outfit Regular", system-ui, Arial, sans-serif',
      "primary-italic": '"Outfit Regular Italic", system-ui, Arial, sans-serif',
      "primary-medium": '"Outfit Medium", system-ui, Arial, sans-serif',
      "primary-medium-italic":
        '"Outfit Medium Italic", system-ui, Arial, sans-serif',
      "primary-bold": '"Outfit Bold", system-ui, Arial, sans-serif',
      "primary-bold-italic":
        '"Outfit Bold Italic", system-ui, Arial, sans-serif',

      "secondary-light": '"Outfit Light", system-ui, Arial, sans-serif',
      "secondary-light-italic":
        '"Outfit Light Italic", system-ui, Arial, sans-serif',
      "secondary": '"Outfit Regular", system-ui, Arial, sans-serif',
      "secondary-italic":
        '"Outfit Regular Italic", system-ui, Arial, sans-serif',
      "secondary-medium": '"Outfit Medium", system-ui, Arial, sans-serif',
      "secondary-medium-italic":
        '"Outfit Medium Italic", system-ui, Arial, sans-serif',
      "secondary-bold": '"Outfit Bold", system-ui, Arial, sans-serif',
      "secondary-bold-italic":
        '"Outfit Bold Italic", system-ui, Arial, sans-serif',
    },
    gridTemplateColumns: {
      // 240px
      xs: `repeat(auto-fit, minmax(15rem, 1fr))`,
      // 256px
      s: `repeat(auto-fit, minmax(16rem, 1fr))`,
      // 288px
      m: `repeat(auto-fit, minmax(18rem, 1fr))`,
      // 320px
      l: `repeat(auto-fit, minmax(20rem, 1fr))`,
      // 384px
      xl: `repeat(auto-fit, minmax(24rem, 1fr))`,
    },
    gridColumn: {
      "1": "span 1 / span 1",
      "2": "span 2 / span 2",
      "3": "span 3 / span 3",
      "4": "span 4 / span 4",
      "5": "span 5 / span 5",
      "6": "span 6 / span 6",
      "7": "span 7 / span 7",
      "8": "span 8 / span 8",
      "9": "span 9 / span 9",
      "10": "span 10 / span 10",
      "11": "span 11 / span 11",
      "12": "span 12 / span 12",
      "full": "1 / -1",
    },
    gridColumnEnd: {
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "8": "8",
      "9": "9",
      "10": "10",
      "11": "11",
      "12": "12",
    },
    gridColumnStart: {
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
      "8": "8",
      "9": "9",
      "10": "10",
      "11": "11",
      "12": "12",
    },
    gridRow: {
      "1": "span 1 / span 1",
      "2": "span 2 / span 2",
      "3": "span 3 / span 3",
      "4": "span 4 / span 4",
      "5": "span 5 / span 5",
      "6": "span 6 / span 6",
      "7": "span 7 / span 7",
      "full": "1 / -1",
    },
    gridRowEnd: {
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
    },
    gridRowStart: {
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
      "6": "6",
      "7": "7",
    },
    borderWidth: {
      none: "0",
      xs: "1px",
      s: "2px",
      m: "3px",
      l: "4px",
      xl: "5px",
      DEFAULT: "1px",
    },
    borderRadius: {
      none: "0",
      xs: "0",
      s: "0",
      m: "0",
      l: "0",
      xl: "0",
      full: "0",
      DEFAULT: "0",
    },
    transitionDuration: {
      none: "0ms",
      xs: `100ms`,
      s: `150ms`,
      m: `200ms`,
      l: `300ms`,
      xl: `500ms`,
      "2xl": `1000ms`,
      DEFAULT: `150ms`,
    },
    ringWidth: {
      "none": "0",
      "xs": "1px",
      "s": "2px",
      "m": "4px",
      "l": "8px",
    },
    ringOffsetWidth: {
      "xs": "1px",
      "s": "2px",
      "m": "4px",
      "l": "8px",
    },
    screens: {
      //see @components/AssetAssessmentForm/Synthesis/ExternalLinks.tsx
      "xxs": "0px",
      "xs": "360px",
      "sm": "640px",
      "md": "768px",
      "lg": "1024px",
      "xl": "1280px",
      "2xl": "1536px",
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        sm: "2rem",
        lg: "4rem",
        xl: "5rem",
        "2xl": "6rem",
      },
    },
    keyframes: {
      clickButton: {
        "0%,100%": { transform: "translateY(0)" },
        "25%": { transform: "translateY(2px)" },
      },
      unfold: {
        "0%": { maxHeight: "0" },
        "100%": { maxHeight: "500px" },
      },
      fold: {
        "0%": { maxHeight: "500px" },
        "100%": { maxHeight: "0" },
      },
      chartWidth: {
        "0%": { width: "0" },
        "end": { width: (percent: number) => `${percent}%` },
      },
      toastFormBottom: {
        "0%": { bottom: "-100px" },
        "100%": { bottom: "0" },
      },
    },
    animation: {
      "button-click": "clickButton 300ms ease-in-out",
      "accordion-unfold": "unfold 500ms ease-in",
      "accordion-fold": "fold 500ms ease-out",
      "chart-horizontal": "chartWidth 500ms ease-in-out",
      "toast-from-bottom": "toastFormBottom 500ms ease-out",
    },
    transitionProperty: {
      "color": "background-color",
      "height": "height",
    },
    flexBasis: {
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      "12/12": "100%",
      "full": "100%",
    },
  },
};

export default configSapians;
